import React from "react";
import { Contact, Detail, Plans } from "../../components";
import { planesHogar, planesUltra } from "../../data/data";
import { planesHogarDetails, planesUltraDetails } from "../PlansPage/Data";

export const TestPage = () => {
	return (
		<>
			{/* <Plans {...planesHogarDetails} planType={planesHogar} /> */}
			{/* <Plans
				{...planesUltraDetails}
				planType={planesUltra}
				tipo={planesUltraDetails.id}
			/> */}

			<Detail />
		</>
	);
};
