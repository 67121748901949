import styled, { keyframes } from "styled-components";
import { TelephoneFill } from "@styled-icons/bootstrap/TelephoneFill";

import { Whatsapp } from "@styled-icons/icomoon/Whatsapp";

//Animations Contact

const rotate = keyframes`
  from {
    transform: translateY(-4px);
  }

  to {
    transform: translateY(6px);
  }
`;

//End Animations

//ICONS

export const WA = styled(Whatsapp)`
	color: #d58936;
	padding: 0px 10px;
	width: 50px;
	text-align: center;
`;

export const Telephone = styled(TelephoneFill)`
	color: #d58936;
	padding: 0px 10px;
	width: 50px;
	text-align: center;
`;

//END ICONS

export const HigherContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
`;

export const ContactContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 20px 20px;
	background: #f2cc9e;
	padding: 20px 20px;
	flex: 0.75;
	@media screen and (max-width: 500px) {
		flex: 1;
	}
`;

export const Title = styled.h1`
	font-size: 3.5rem;
	line-height: 1.1;
	max-width: 900px;
	color: #000;
	padding-top: 50px;
	padding-left: 30px;
	padding-right: 30px;
	margin: -2px 0px 0px 0px;
	/* background-color: #1c1c1c; */
	/* color: whitesmoke; */
	border-radius: 0%;
	padding-bottom: 50px;
	font-weight: 300;
	line-height: 1.2;
	text-align: center;

	@media screen and (max-width: 968px) {
		padding-bottom: 65px;
		/* max-width: 300px; */
		font-size: 40px;
		margin-bottom: 30px;
		padding-bottom: 0;
	}
`;

export const Card = styled.div`
	z-index: 3;
	background: #000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	height: 200px;

	transform: translateY(6px);
	animation: ${rotate} 0.5s normal ease-in;

	&:hover {
		transform: scale(1.02);
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}
`;

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 20px 20px;
`;

export const TextCard = styled.h2`
	font-size: 25px;
	font-weight: 600;
	text-align: center;
	color: #d58936;

	span: {
		height: 100%;
		text-align: center;
	}
`;

export const FormWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
`;
