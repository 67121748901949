import React from "react";
import { Route, Routes } from "react-router-dom";
import { Cobertura } from "../components/CoberturaScreen/Cobertura";
import { SpeedTest } from "../components/SpeedScreen/SpeedTest";
import { PricesDetail } from "../components/PricesScreen/PricesDetail";
import { PricesScreen } from "../components/PricesScreen/PricesScreen";
import { PricesScreen2 } from "../components/PricesScreen/PriceScreen2";
import { PlanesCorporativos } from "../components/PricesScreen/PlanesCorporativos";
import { PricesDetail2 } from "../components/PricesScreen/PricesDetail2";
import { ContactPage } from "../pages/Contact/ContactPage";
import { TestPage } from "../pages/Test/TestPage";

import { HomePage } from "../pages/Home/HomePage";
import { Detail } from "../components";
import { Plans } from "../components";
import { planesHogar, planesUltra } from "../pages/PlansPage/Data";
import {
	planesHogarDetails,
	planesUltraDetails,
} from "../pages/PlansPage/Data";

export const PublicRoutes = () => {
	return (
		<>
			<Routes>
				<Route path="*" element={<HomePage />} />
				<Route path="cobertura" element={<Cobertura />} />
				<Route path="speedtest" element={<SpeedTest />} />
				<Route path="planes-corporativos" element={<PlanesCorporativos />} />
				<Route
					path="planes-hogar/:planId"
					element={
						<Detail
							planesType={planesHogar}
							hogar={true}
							tipo={planesHogarDetails.id}
						/>
					}
				/>
				<Route
					path="planes-ultra/:planId"
					element={
						<Detail
							planesType={planesUltra}
							hogar={false}
							tipo={planesUltraDetails.id}
						/>
					}
				/>
				<Route path="contacto" element={<ContactPage />} />
				<Route path="test" element={<TestPage />} />
				<Route
					path="planes-hogar"
					element={
						<Plans
							{...planesHogarDetails}
							planType={planesHogar}
							tipo={planesHogarDetails.id}
							hogar={true}
						/>
					}
				/>
				<Route
					path="planes-ultra"
					element={
						<Plans
							{...planesUltraDetails}
							planType={planesUltra}
							tipo={planesUltraDetails.id}
						/>
					}
				/>
				<Route path="test" element={<TestPage />} />
				{/* <Route path="planes" element={<PlansPage />}>
					<Route path=":planName" element={<PlanScreen />}>
						<Route  path=":planId" element={<PlanDetailScreen/>} />
					</Route>
				</Route> */}
			</Routes>
		</>
	);
};
