import React, { useEffect, useState } from "react";

import validator from "validator";

export const useForm = (initialState1: {
	name: string;
	email: string;
	address: string;
	cellphone: string;
	text: string;
}) => {
	const [formValues, setValues] = useState(initialState1);
	const [filled, setFilled] = useState(false);

	const [error, setError] = useState(() =>
		Object.keys(initialState1).reduce(
			(attrs, key: any) => ({
				...attrs,
				[key]: true,
			}),
			{}
		)
	);

	type CheckType = {
		name: boolean;
		email: boolean;
		address: boolean;
		cellphone: boolean;
		text: boolean;
	};

	const [check, setCheck] = useState<CheckType | any>(() =>
		Object.keys(initialState1).reduce(
			(attrs, key: any) => ({
				...attrs,
				[key]: false,
			}),
			{}
		)
	);

	const reset = () => {
		setValues(initialState1);
	};

	//monitorear los cambios cuando se escribe
	const handleInputChange = ({ target }: any) => {
		setValues((prevState: any) => ({
			...prevState,
			[target.name]: target.value,
		}));
		//empezar a chequear cuadno hay un cambio en el box
		setCheck((prevState: any) => ({
			...prevState,
			[target.name]: true,
		}));
	};

	//Manejar los estados de error
	const errorHandling = (key: string, boolean: boolean) => {
		setError((prevState) => {
			let state = { ...prevState, [key]: boolean };
			setFilled(Object.values(state).every((item: any) => item === false));

			return state;
		});
	};

	//codigo de validaciones para campos especificos
	//LLama a las validaciones cada vez que los valores cambian
	useEffect(() => {
		if (formValues.hasOwnProperty("name") && check.name === true) {
			if (formValues?.name.trim().length >= 5) {
				errorHandling("name", false);
			} else if (formValues?.name.trim().length < 5) {
				errorHandling("name", true);
			}
		}

		if (formValues.hasOwnProperty("email") && check?.email === true) {
			if (validator.isEmail(formValues?.email)) {
				errorHandling("email", false);
			} else {
				errorHandling("email", true);
			}
		}
		if (formValues.hasOwnProperty("address") && check?.address === true) {
			if (formValues?.address.trim().length > 0) {
				errorHandling("address", false);
			} else if (formValues?.address.trim().length === 0) {
				errorHandling("address", true);
			}
		}

		if (formValues.hasOwnProperty("cellphone") && check?.cellphone === true) {
			if (
				validator.isInt(formValues?.cellphone) &&
				formValues?.cellphone.trim().length > 5
			) {
				errorHandling("cellphone", false);
			} else if (formValues.cellphone.trim().length === 0) {
				errorHandling("cellphone", true);
			}
		}
		if (formValues.hasOwnProperty("text") && check?.text === true) {
			if (formValues?.text.trim().length >= 10) {
				errorHandling("text", false);
			} else if (formValues?.text.trim().length < 10) {
				errorHandling("text", true);
			}
		}

		return () => {};
	}, [formValues, check]);

	return {
		formValues,
		handleInputChange,
		reset,

		error,
		check,
		filled,
	};
};
