import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	GoogleMap,
	InfoWindow,
	Marker,
	Polygon,
	useLoadScript,
} from '@react-google-maps/api';

import { Helmet } from 'react-helmet';
import { zona1, zona2, zona3, zona4 } from './puntosCobertura';
// import { Locate } from './Locate';
import { Search } from './Search';

const mapContainerStyle = {
	height: '100%',
	width: '100%',
};

const center = { lat: -1.070361, lng: -80.446207 };

const options = {
	fillColor: 'yellow',
	fillOpacity: 0.3,
	strokeColor: 'black',
	strokeOpacity: 1,
	strokeWeight: 2,
	clickable: false,
	draggable: false,
	editable: false,
	geodesic: false,
	zIndex: 1,
};

const libraries = ['places'];

export const Cobertura = ({ location }) => {
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_API_GOOGLE,
		// librerias adicionales de google que se añaden para usar el google maps
		libraries,
	});

	const [markers, setMarkers] = useState([]);
	const [selected, setSelected] = useState(null);

	//Function para crear marcaadors cuando el usuario da clicks
	// const onMapClick = useCallback((event) => {
	// 	setMarkers((current) => [
	// 		...current,
	// 		{
	// 			lat: event.latLng.lat(),
	// 			lng: event.latLng.lng(),
	// 			time: new Date(),
	// 		},
	// 	]);
	// }, []);

	//Se manteien la referencia a la instancia actual del mapa
	// para que no genere ningun re render en algun llamado del componente
	const mapRef = useRef();

	const onMapLoad = useCallback((map) => {
		mapRef.current = map;
	}, []);

	const panTo = useCallback(({ lat, lng }) => {
		mapRef.current.panTo({ lat, lng });
		mapRef.current.setZoom(18);
		setMarkers((current) => [
			...current,
			{
				lat,
				lng,
				time: new Date(),
			},
		]);
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [location]);

	if (loadError) {
		return 'Error Map';
	}

	//Pantalla de Carga
	if (!isLoaded) {
		return (
			<div className="vertical-center" style={{ backgroundColor: '#1c1c1c' }}>
				<div className="container">
					<div className="row justify-content-center">
						<div className="sk-chase">
							<div className="sk-chase-dot"></div>
							<div className="sk-chase-dot"></div>
							<div className="sk-chase-dot"></div>
							<div className="sk-chase-dot"></div>
							<div className="sk-chase-dot"></div>
							<div className="sk-chase-dot"></div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	// console.log(isLoaded);

	return (
		<>
			<Helmet>
				<title>Cobertura | XfinityNet</title>
				<meta name="description" content="Zona de Cobertura de XfinityNet" />
			</Helmet>

			<div className="flex-wrapper2">
				<Search panTo={panTo} />
				{/* <Locate panTo={panTo} /> */}
				<GoogleMap
					mapContainerClassName="mapas"
					id="cobertura"
					mapContainerStyle={mapContainerStyle}
					zoom={15}
					onLoad={onMapLoad}
					center={center}
					// onClick={onMapClick}
				>
					{markers.map((marker) => (
						<Marker
							key={marker.time.toISOString()}
							position={{
								lat: marker.lat,
								lng: marker.lng,
							}}
							onClick={() => {
								setSelected(marker);
							}}
						/>
					))}

					{selected ? (
						// informacion cuando das click a un marcador
						<InfoWindow
							position={{ lat: selected.lat, lng: selected.lng }}
							onCloseClick={() => {
								setSelected(null);
							}}
						>
							<div>
								<h5> Marcador Colocado</h5>
								<p> {`lat: ${selected.lat} `}</p>
								<p> {` lng: ${selected.lng}`}</p>
							</div>
						</InfoWindow>
					) : null}

					<Polygon paths={zona1} options={options} />
					<Polygon paths={zona2} options={options} />
					<Polygon paths={zona3} options={options} />
					<Polygon paths={zona4} options={options} />
				</GoogleMap>
			</div>
		</>
	);
};
