import React from 'react'
import { MenuItems } from './Dropdown.element'

export const DropdownItem = ({children, destiny}) => {
    return (
        <MenuItems to={destiny}>

            {children}
            
        </MenuItems>
    )
}
