import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

//implementacion de tamano toal de cargas con axios para progress bars
// Fetch con Axios
export const fetchSinTokenAxios = (
	endpoint,
	data,
	method = 'get',
) => {
	const url = `${baseURL}/${endpoint}`; //localhost:400/api/auth

	if (method === 'get') {
		return axios({
			method: method,
			url: url,

			headers: {
				'Content-type': 'application/json',
			},
			onDownloadProgress: (progressEvent) => {
				console.log(progressEvent);
				const { loaded, total } = progressEvent;
				let percent = Math.floor((loaded * 100) / total);
				// dispatch(uiProgressBar(percent));
				console.log(`${loaded}kb of  ${total}kb  | ${percent}%`);
			},
		});
	} else {
		return axios({
			method: method,
			url: url,

			headers: {
				'Content-type': 'application/json',
			},
			data: data,
		});
	}
};

export const fetchTokenAxios = (endpoint, data, method = 'get') => {
	const url = `${baseURL}/${endpoint}`; //localhost:400/api/auth
	const token = localStorage.getItem('token') || '';

	if (method === 'get') {
		return axios({
			method: 'get',
			url: url,
			headers: {
				'x-token': token,
				'Content-type': 'application/json',
			},
			onDownloadProgress: (progressEvent) => {
				console.log(progressEvent);
				const { loaded, total } = progressEvent;
				let percent = Math.floor((loaded * 100) / total);
				console.log(`${loaded}kb of  ${total}kb  | ${percent}%`);
			},
		});
	} else {
		return axios({
			method: method,
			url: url,
			headers: {
				'x-token': token,
				'Content-type': 'application/json',
			},
			data: data,
		});
	}
};
