import styled from "styled-components";




export const InfoSec = styled.div`
	/* margin-top: 0px; */
	/* transform: translateX(-100%); */

 	/* left: ${({ inView }) => (inView ? '-100%' : '0' )  }; */
	color: #fff;
	padding: 50px 0;
	background: ${({ lightBg }) => (lightBg ? "#f2cc9e" : "#755630")};

`;

export const InfoRow = styled.div`
overflow-y: hidden;
overflow-x: hidden;
	display: flex;
	margin: 0px -15px -15px -15px;

	flex-wrap: wrap;
	align-items: center;
	flex-direction: ${({imgStart}) => (imgStart ? "row-reverse" : "row")};
`;

export const InfoColumn = styled.div`
	margin-bottom: 15px;
	padding-right: 15px;
	padding-left: 15px;
	max-width: 50%;
    flex: 1;
	display: flex;
	
	@media screen and (max-width: 768px) {
		max-width: 100%;
		flex-basis: 100%;
		display: flex;
		justify-content: center;
	}
`;

export const TextWrapper = styled.div`

	transition: transform 0.6s ease-in-out;
	/* transform:${({ inView }) => (inView ? 'translateX(0%)' : 'translateX(-300%)' )  } ;  */
	padding-top: 0;
	padding-bottom: 60px;
    display: flex;
    flex-direction: column;
  
	@media screen and (max-width: 768px) {
		padding-bottom: 65px;
		/* max-width: 300px; */
	
	}
`;

export const TopLine = styled.div`
	color: ${({ lightTopLine }) => (lightTopLine ? "#f1f2f4" : "#000")};
	font-size: 18px;
	line-height: 16px;
	letter-spacing: 1.4px;
	margin-bottom: 16px;
`;

export const Heading = styled.h1`
	margin-bottom: 24px;
	font-size: 48px;
	line-height: 1.1;
	max-width: 500px;
	color: ${({ lightText }) => (lightText ? "#f1f2f4" : "#000")};

	@media screen and (max-width: 800px) {
		padding-bottom: 65px;
		/* max-width: 300px; */
		font-size: 30px;
		margin-bottom: 30px;
		padding-bottom: 0;
	
	}
`;

export const Subtitle = styled.p`
	max-width: 500px;
	margin-bottom: 10px;
	font-size: 18px;
	line-height: 24px;
	color: ${({ lightTextDesc }) => (lightTextDesc ? "#f1f2f4" : "#000")};
`;


export const ImgWrapper = styled.div`

	/* transform:${({ inView }) => (inView ? 'translateX(0px)' : 'translateX(50%)' )  } ; */
	transition: transform 0.70s ease-in;
	max-width: 500px;
    display: flex;
    justify-content: ${({start})=> (start?'flex-start':'flex-end')};
	
`;

export const Img = styled.img`


    padding-right: 0;
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
	border-radius: 10px;

    /* max-height: 600px; */

`;