import React, { useState, useEffect } from "react";
import {
	Subtitle,
	SecondTitle,
	Img,
	Card,
	Auxiliary,
} from "./SecondaryDashBoard.elements";

export const SecondaryDashBoard = ({
	img,
	desc,
	lightTextDesc,
	secondtitle,
}) => {
	return (
		<>
			{/* <Title>Nuestro Compromiso</Title> */}

			<Card>
				<Auxiliary>
					<Img src={img} />
					<SecondTitle>{secondtitle}</SecondTitle>
				</Auxiliary>

				<Subtitle lightTextDesc={lightTextDesc}>{desc}</Subtitle>
			</Card>
		</>
	);
};
