import { Link } from "react-router-dom";
import styled from "styled-components";
import { GameController } from "@styled-icons/ionicons-solid/GameController";
import { Business } from "@styled-icons/boxicons-solid/Business";
import { HouseFill } from "@styled-icons/bootstrap/HouseFill";

export const Company = styled(Business)`
	width: 75px;

	color: #d58936;
	@media screen and (max-width: 769px) {
		width: 65px;
		/* max-width: 300px; */
	}

	@media screen and (max-width: 500px) {
		width: 40px;
		/* max-width: 300px; */
	}
`;

export const Control = styled(GameController)`
	width: 75px;

	color: #d58936;
	@media screen and (max-width: 769px) {
		width: 65px;
		/* max-width: 300px; */
	}

	@media screen and (max-width: 500px) {
		width: 50px;
	}
`;

export const House = styled(HouseFill)`
	width: 75px;

	color: #d58936;
	@media screen and (max-width: 769px) {
		width: 65px;
		/* max-width: 300px; */
	}

	@media screen and (max-width: 500px) {
		width: 50px;
		/* max-width: 300px; */
	}
`;

export const HomeLinks = styled(Link)`
	text-decoration: none;

	/* border-bottom: 2px solid transparent; */
	transition: all 0.5s ease;

	&:hover {
		/* border-bottom: 2px solid black; */
		/* background: #d58936; */
		text-decoration: none;
		/* color: #fff; */
	}

	@media screen and (max-width: 968px) {
		text-align: center;

		&:hover {
			transition: all 0.5s ease;
		}
	}
`;

export const TextWrapper = styled.div`
	padding-top: 0;
	padding-bottom: 10px;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 968px) {
		padding-bottom: 20px;
		/* max-width: 300px; */
	}
`;

export const Heading = styled.h1`
	font-size: 4.5rem;
	line-height: 1.1;
	max-width: 900px;
	color: #f1f2f4;
	padding-top: 50px;
	padding-left: 30px;
	padding-right: 30px;
	margin: -2px 0px 0px 0px;
	/* background-color: #1c1c1c; */
	/* color: whitesmoke; */
	border-radius: 0%;
	padding-bottom: 50px;
	font-weight: 300;
	line-height: 1.2;

	@media screen and (max-width: 968px) {
		padding-bottom: 65px;
		/* max-width: 300px; */
		font-size: 40px;
		margin-bottom: 30px;
		padding-bottom: 0;
	}
`;

export const MainSec = styled.div`
	/* margin-top: 0px; */
	/* transform: translateX(-100%); */

	/* left: ${({ inView }) => (inView ? "-100%" : "0")}; */
	color: #fff;
	margin-top: -5px;
	padding: 0px 0;
	/* background: rgba(220, 109, 28, 0.09); */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* flex: 1; */
	z-index: 2;
	/* position:absolute; */
	font-weight: 400;
	height: 600px;

	@media screen and (max-width: 769px) {
		height: 500px;
		/* max-width: 300px; */
	}

	@media screen and (max-width: 500px) {
		height: 500px;
		/* max-width: 300px; */
	}
`;

export const Subtitle = styled.p`
	max-width: 700px;
	margin-bottom: 1px;
	font-size: 25px;
	line-height: 30px;
	color: #f1f2f4;
`;

export const IconWrapper = styled.div`
	display: flex;
	position: relative;
	flex-wrap: wrap;
	/* flex: 1; */
	align-content: center;
	justify-content: space-between;
	text-align: center;
	@media screen and (max-width: 968px) {
		flex-wrap: nowrap;
	}
`;

export const Iconos = styled.div`
	width: 300px;
	text-align: center;
	padding: 20px 20px;
	/* padding-right: 60px; */
	/* padding-left: 60px; */
	transition: transform 0.5s ease;

	&:hover {
		transform: scale(1.25);
	}

	@media screen and (max-width: 769px) {
		width: 200px;
		padding: 20px 20px;
		/* max-width: 300px; */
	}

	@media screen and (max-width: 500px) {
		padding-top: 0px;
		padding-bottom: 0px;
		padding-right: 0px;
		padding-left: 0px;
		width: 100px;
		/* max-width: 300px; */
	}
`;

export const TextIcons = styled.div`
	color: #f1f2f4;
	font-size: 1.75rem;
	line-height: 1.2;
	text-align: center;
	@media screen and (max-width: 768px) {
		font-size: 15px;
		/* max-width: 300px; */
	}
`;

export const MainBg = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 110%;
	overflow: hidden;

	z-index: -1;
`;

export const VideoBg = styled.video`
	width: 100%;
	height: 100%;
	--o-object-fit: cover;
	object-fit: cover;
	background: #232a34;
`;
