import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import GlobalStyle, { GeneralBackground } from "../globalStyles";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { Navbar } from "../components/Navbar/Navbar";
import { Footer } from "../components/Footer/Footer";
import { PublicRoutes } from "./PublicRoutes";
import { PrivateDashboard } from "../components/Private/PrivateDashboard";
import { PrivateRoutes } from "./PrivateRoutes";

export default () => {
	return (
		<>
			<GlobalStyle />
			<GeneralBackground />

			<MessengerCustomerChat
				pageId="114510763680323"
				appId="340967083873957"
				language="es_LA"
				shouldShowDialog={true}
			/>

			<BrowserRouter>
				<Navbar />
				<Routes>
					<Route path="/*" element={<PublicRoutes />} />

					<Route
						path="dashboard/*"
						element={
							<PrivateRoutes role={"admin"} isLogged={true}>
								<PrivateDashboard />
							</PrivateRoutes>
						}
					/>
				</Routes>
				<Footer />
			</BrowserRouter>
		</>
	);
};
