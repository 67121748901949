import React, { useMemo, useState, useEffect, useRef } from "react";
import {
	Navigate,
	useNavigate,
	useParams,
	useLocation,
} from "react-router-dom";
import { Form } from "..";
import { getById } from "../../helper/getById";
import { Card, CardTitle, CardDesc, CardFooter } from "../Plans/Plans.elements";
import {
	Back,
	BackButton,
	DescDetails,
	FormWrapper,
	HigherContainer,
	InfoPlanContainer,
	Span,
	TextWrapper,
	Title,
} from "./Details.element";

export const Detail = ({ planesType, hogar, tipo }) => {
	const { planId } = useParams();

	const [width, setWidth] = useState(window.innerWidth);
	const [mobile, setMobile] = useState();
	const navigate = useNavigate();
	const locationRef = useRef();
	let location = useLocation();

	const [id, setId] = useState(0);

	const idRef = useRef();

	const handleGoBack = () => {
		navigate(-1);
	};

	// console.log(planId);

	const planType = useMemo(
		() => getById(planId, planesType),
		[planId, planesType]
	);
	const handleResize = () => {
		setWidth(window.innerWidth);
	};
	useEffect(() => {
		// console.log(location);
		if (location.pathname !== locationRef.current) {
			window.scrollTo({ top: 0, behavior: "smooth" });
			locationRef.current = location.pathname;
		}
	}, [location.key, location.pathname]);

	useEffect(() => {
		if (planType) {
			window.addEventListener("resize", handleResize);
		}

		if (width < 700) {
			setMobile(true);
		} else {
			setMobile(false);
		}

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [width, planType]);

	let testarray = [];

	//automatizar tarea, hacer esto mas generico
	if (tipo === "planes-hogar") {
		testarray = ["planbasic", "planpro", "planblast"];
	} else {
		testarray = ["planultrah", "planultras", "planultrax"];
	}

	//para navegacion entre componentes por medio del useNavidate y dando click en un elemento
	useEffect(() => {
		console.log(idRef.current, id);
		console.log(tipo);
		if (id !== idRef.current) {
			if (id < testarray.length) {
				if (idRef.current != testarray.length - 1) {
					navigate(`/${tipo}/${testarray[id]}`, { replace: true });
				}
				idRef.current = id;
			}
			if (id === testarray.length) {
				setId((prevState) => 0);
				idRef.current = undefined;
			}
		}

		return () => {};
	}, [id]);

	if (!planType) {
		return <Navigate to="/home" />;
	}
	const { title, precio, velocidad, desc } = planType;

	return (
		<>
			<HigherContainer>
				<InfoPlanContainer>
					{!mobile && (
						<BackButton onClick={handleGoBack}>
							<Span>
								<Back />
							</Span>
							Volver
						</BackButton>
					)}
					<TextWrapper>
						<Title>Unete a la mejor red de Manabí</Title>
						<DescDetails>
							Tan solo llena el formulario y te contactaremos pronto!
						</DescDetails>
					</TextWrapper>
					<Card hogar={hogar} disable={true} onClick={() => setId(id + 1)}>
						<CardTitle>
							{`${title}
					${velocidad}`}
						</CardTitle>

						<CardDesc>{`${precio} / mes (inluye IVA)`}</CardDesc>
						<CardFooter>{desc}</CardFooter>
					</Card>
				</InfoPlanContainer>
				<FormWrapper>
					<Form planId={planId}></Form>
				</FormWrapper>
			</HigherContainer>
		</>
	);
};
