import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
    /* background-color: black; */
    /* background-color: rgba(241, 242, 244, 1); */
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Basier Circle', sans-serif;
  
    text-decoration: none;
    /* background: khaki; */
}


`;

export const GeneralBackground = styled.div`
	background-color: rgba(241, 242, 244, 1);
`;

export const Row = styled.div`
	display: flex;

	margin-right: auto;
	margin-left: auto;
`;

export const Container = styled.div`
	width: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 50px;
	padding-left: 50px;

	@media screen and (max-width: 991px) {
		padding-right: 30px;
		padding-left: 30px;
	}
`;

export const Button = styled.button`
	border-radius: 0.75rem;
	background: ${({ disabled }) => (disabled ? "#9b9b9b" : "black")};
	white-space: nowrap;
	padding: 0.625rem 1.5rem;
	color: ${({ primary }) => primary || "white"};
	font-size: ${({ fontBig }) => (fontBig ? "25px" : "19px")};
	outline: none;
	border: none;
	-webkit-tap-highlight-color: transparent;
	font-weight: 500;
	transition: all 0.3s ease-out;
	width: ${({ isLoading }) => (isLoading ? "25%" : "50%")};
	pointer-events: all;
	cursor: pointer;

	&:hover {
		transition: all 0.3s ease-out;
		background: #fff;
		background: ${({ primary }) => primary || "#d58936"};
	}
	&:focus {
		outline-color: transparent;
	}
	@media screen and (max-width: 960px) {
		width: 50%;
	}
`;

export default GlobalStyle;
