import { Link } from "react-router-dom";
import styled from "styled-components";


export const DropdownMobileCont = styled.div`
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align:center;
overflow: hidden;
position:relative;

max-height: ${({show})=> (show ? "100%" : "0")};
opacity:${({show})=> (show ? "1" : "0")}; ;


transition: all 0.5s ease-in-out;
`;


export const DropdownContainer = styled.div`
position: absolute;
top:70px;
width:200px;
transform: translateX(-45%);
padding: 1 rem;
border: black;
border-radius: 10px;
overflow: hidden;
background-color: #2c2b2b;
display: flex;
flex-direction: column;
transition: all 0.3s;


@media screen and (max-width: 960px){
        width: 300px;
        top:80px;
      left: 48%;
        text-align:center;
        display: flex;
        flex-direction: column;
        justify-content: center;
     
    }

`;


export const Text = styled.p`
 text-align:center;
color:white;
`;

export const MenuItems = styled(Link)`
height:50px;
display:flex;
text-align:center;
align-items: center;
border-radius: 10px;
padding: 0.6rem;
transition: background-color 0.2s ease-in;

&:hover{
    background-color: rgb(255,194,126,0.27);
text-decoration: none;
}

`;