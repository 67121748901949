import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const alerta = (title, footer, icon = "succes" | "error" | "info") =>
	MySwal.fire({
		title: `<p>${title}</p>`,
		footer: footer,
		didOpen: () => {
			// `MySwal` is a subclass of `Swal`
			//   with all the same instance & static methods
			// MySwal.clickConfirm();
		},
		timerProgressBar: true,
		timer: 3000,
		color: "#000",
		background: "#fff",
		icon: icon,
	});
