import React, { memo, useState } from "react";
import { Button } from "../../globalStyles";
import { useForm } from "../../hooks/useForm";
import { useFetch } from "../../hooks/useFetch";
import {
	FormInput,
	FormContainer,
	InputWrapper,
	FormLabel,
	ImgWrapper,
	Img,
	Desc,
	InputContainer,
	Span,
	Info,
} from "./Form.element";

import svg from "../../images/xfinitylogo1.svg";
// import Swal from "sweetalert2";
import { Spinner } from "../Logos/Spinner";
import { TooltipContainer, TooltipText } from "../Logos/Tooltip";
import { alerta } from "../Logos/Swal";
import { title } from "process";

export const Form = memo(({ planId }) => {
	const { formValues, handleInputChange, error, check, filled } = useForm({
		name: "",
		email: "",
		cellphone: "",
		address: "",
		text: "",
	});

	const { name, email, cellphone, address, text } = formValues;
	const { progress, fetch, load } = useFetch("email/newEmail", "post");

	const [isFetchComplete, setIsFetchComplete] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		formValues.plan = planId;

		if (load === false) {
			console.log(formValues);
			const resp = await fetch(formValues);

			if (resp) {
				if (resp.hasOwnProperty("data")) {
					await alerta("Gracias Por Contactarnos", "Correo Enviado", "success");
					console.log(resp.data);
					console.log(progress);
					setIsFetchComplete(true);
				}
			} else {
				console.log("peticion cancelada");
			}
		}
	};

	return (
		<>
			<FormContainer>
				{/* {isFetchComplete && } */}
				<ImgWrapper>
					<Span>
						<Info />
					</Span>
					<Desc>Envíanos un mensaje</Desc>

					{/* <Img src={svg} alt={"xfinitylogo"} /> */}
				</ImgWrapper>

				<InputContainer>
					<InputWrapper>
						<FormLabel htmlFor="Nombre">Nombre</FormLabel>
						<FormInput
							name="name"
							type="text"
							placeholder="Nombres Completos"
							value={name}
							onChange={handleInputChange}
							error={error?.name}
							check={check?.name}
						></FormInput>
					</InputWrapper>

					<InputWrapper>
						<FormLabel htmlFor="Email">Email</FormLabel>
						<FormInput
							name="email"
							type="email"
							placeholder="Tu email aquí"
							value={email}
							onChange={handleInputChange}
							error={error?.email}
							check={check?.email}
						></FormInput>
					</InputWrapper>

					<InputWrapper>
						<FormLabel htmlFor="Cellphone">Celular</FormLabel>
						<FormInput
							name="cellphone"
							type="tel"
							placeholder="Número de teléfono"
							value={cellphone}
							onChange={handleInputChange}
							error={error?.cellphone}
							check={check?.cellphone}
						></FormInput>
					</InputWrapper>

					<InputWrapper>
						<FormLabel htmlFor="Street-address">Direccion</FormLabel>
						<FormInput
							name="address"
							type="street-address"
							placeholder="Dirección"
							value={address}
							onChange={handleInputChange}
							error={error?.address}
							check={check?.address}
						></FormInput>
					</InputWrapper>

					<InputWrapper>
						<FormLabel htmlFor="Text">Mensaje</FormLabel>
						<FormInput
							name="text"
							type="text"
							placeholder="Mensaje"
							value={text}
							onChange={handleInputChange}
							error={error?.text}
							check={check?.text}
						></FormInput>
					</InputWrapper>
				</InputContainer>
				<TooltipContainer>
					<Button
						type="submit"
						onClick={handleSubmit}
						disabled={!filled}
						isLoading={load}
					>
						{!load && "Enviar"} {load && <Spinner />}
					</Button>

					{!filled && <TooltipText>Rellena todos los campos</TooltipText>}
				</TooltipContainer>
			</FormContainer>
		</>
	);
});
