import { Link } from "react-router-dom";
import styled from "styled-components";

export const PlansContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	/* height: 100%; */
	width: 100vw;
	padding: 80px 10px;
	position: relative;
	z-index: 999;
	margin: -80px 0px 0px 0px;
`;

export const Title = styled.h1`
	font-size: 3.5rem;
	line-height: 1.1;
	z-index: 2;
	/* color: #fff; */
	padding-top: 50px;
	padding-left: 30px;
	padding-right: 30px;
	margin: -2px 0px 0px 0px;

	/* background-color: #1c1c1c; */
	/* color: whitesmoke; */
	border-radius: 0%;
	padding-bottom: 50px;
	font-weight: 300;
	line-height: 1.2;
	text-align: center;
	background: ${({ hogar }) =>
		hogar ? "white" : "-webkit-linear-gradient(#8b0000, #8a2be2)"};
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;

	@media screen and (max-width: 968px) {
		padding-bottom: 65px;
		/* max-width: 300px; */
		font-size: 40px;
		margin-bottom: 30px;
		padding-bottom: 0;
	}
`;

export const DescPlans = styled.h2`
	font-size: 2rem;
	z-index: 5;
	font-weight: 600;
	text-align: center;
	color: #fff;
	@media screen and (max-width: 568px) {
		font-size: 1.5rem;
	}
`;

export const Wrapper = styled.div`
	z-index: 3;

	padding: 50px 20px;
	justify-content: space-around;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	grid-gap: 10px;

	@media screen and (max-width: 1000px) {
		/* max-width: 300px; */
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 180px;
		grid-row-gap: 50px;
		justify-items: center;
		align-items: center;
	}

	@media screen and (max-width: 900px) {
		/* max-width: 300px; */
		grid-template-columns: 1fr;
		padding: 0 20px;
	}
`;

export const CardTitle = styled.h2`
	font-size: 2.5rem;
	font-weight: 600;
	text-align: center;
	color: #fff;
`;

export const CardDesc = styled.h3`
	font-size: 2rem;
	font-weight: 600;
	text-align: center;
	color: #fff;
`;

export const CardFooter = styled.h4`
	font-size: 1rem;
	font-weight: 600;
	text-align: center;
	color: #fff;
`;

export const Bg = styled.img`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	--o-object-fit: cover;
	object-fit: cover;
	object-position: center;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

export const Layer = styled.div`
	background-color: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const CardLinks = styled(Link)`
	&:hover {
		text-decoration: none;
	}

	&:focus {
		text-decoration: none;
	}
`;

export const DescContainer = styled.div`
	text-align: center;
	width: 50%;
	padding: 10px 10px;
	margin: 0 auto 10px auto;
	z-index: 0;
	background-color: rgba(0, 0, 0, 0.7);
	border-radius: 10px;
	@media screen and (max-width: 770px) {
		/* max-width: 300px; */

		width: 100%;
	}
`;

export const Card = styled.div`
	z-index: 3;
	background: ${({ hogar }) =>
		hogar
			? "linear-gradient(#552d2db5,#96622bbf)"
			: "linear-gradient(#431f1fc7,#552351c4)"};
	display: flex;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	justify-content: space-around;
	align-items: center;
	border-radius: 10px;
	height: 100%;
	margin: 0px 0px;
	padding: 10px 10px;
	/* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
	transition: all 0.2s ease-in-out;

	@media screen and (min-width: 1000px) {
		/* max-width: 300px; */
		height: 340px;
		width: 350px;
	}

	@media screen and (max-width: 999px) {
		/* max-width: 300px; */
		height: 340px;
		width: 340px;
	}
	&:hover {
		filter: ${({ disable }) => (disable ? "none" : "brightness(1.75)")};

		transform: scale(1.03);
		${CardTitle},${CardDesc},${CardFooter} {
			/* color: black; */
		}
	}
`;
