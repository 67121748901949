import React from "react";
import styled from "styled-components";

export const Tooltip = () => {
	return <></>;
};

export const TooltipText = styled.p`
	opacity: 0;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 9px;
	padding: 10px 10px;
	position: absolute;
	right: auto;
	left: auto;

	top: -95%;
	/* Position the tooltip */
	/* position: absolute; */
	z-index: 99;
	/* @media screen and (max-width: 1024px) {
		right: auto;
		left: auto;
		bottom: -20%;
	}
	@media screen and (max-width: 769px) {
		right: auto;
		left: auto;
		bottom: -30%;
	}
	@media screen and (max-width: 426px) {
		right: auto;
		left: auto;
		bottom: -110%;
	} */
`;

export const TooltipContainer = styled.span`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	pointer-events: none;
	margin: 20px auto 0px auto;
	position: relative;

	&:hover {
		${TooltipText} {
			opacity: 1;
			transition: all 0.3s ease-in;
		}
	}
`;
