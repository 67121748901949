import styled from "styled-components";
import { Container } from "../../globalStyles";
import { Link } from "react-router-dom";
import NavIconImg from "../Logos/NavIconImg";
import { BurgerMenu } from "../Logos/BurgerMenu";

export const Nav = styled.nav`
	/* backdrop-filter:blur(16px); */

	&:before {
		content: "";
		position: absolute;
		z-index: 0;

		backdrop-filter: blur(10px);

		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	background: rgba(0, 0, 0, 0.9) !important;
	position: sticky;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	top: 0;
	z-index: 9999999;

	@media screen and (max-width: 960px) {
		height: ${({ click }) => (click ? "60px" : "60px")};
	}

	@supports not (
		(-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))
	) {
		/* CSS to provide fallback alternative for Firefox and similar browsers */
		& {
			background-color: rgba(0, 0, 0, 1);
		}
	}
`;

export const NavBarContainer = styled(Container)`
	display: flex;
	justify-content: space-between;
	height: 80px;
	z-index: 999;
	${Container}

	align-items: center;

	@media screen and (max-width: 960px) {
		height: 50px;
	}
`;

export const NavLogo = styled(Link)`
	z-index: 999;
	justify-self: center;
	text-decoration: none;
	display: flex;
	align-items: center;
`;

export const NavIcon = styled(NavIconImg)`
	max-width: 200px;
	margin-right: 0.2rem;
	position: absolute;

	@media screen and (max-width: 960px) {
		max-width: 130px;
	}
`;

export const MobileIcon = styled.div`
	display: none;

	@media screen and (max-width: 960px) {
		display: block;
		font-size: 2rem;
		cursor: pointer;
	}
`;

export const Burger = styled(BurgerMenu)`
	display: none;

	@media screen and (max-width: 960px) {
		display: flex;
		flex-direction: column;
		padding-top: 5px;
		padding-left: 8px;
		cursor: pointer;

		i {
			background-color: #d58936;
			width: 32px;
			height: 4px;
			margin: 4px;
			border-radius: 2px;
			transition: all ease 0.5s;
		}

		.open:nth-child(1) {
			transform: rotate(45deg) translateY(8px) translateX(8px);
		}

		.open:nth-child(2) {
			opacity: 0;
		}

		.open:nth-child(3) {
			transform: rotate(-45deg) translateY(-8px) translateX(9px);
		}

		.close:nth-child(1) {
			transform: rotate(0) translateY(0);
		}

		.close:nth-child(2) {
			opacity: 1;
		}

		.close:nth-child(3) {
			transform: rotate(0) translateY(0);
		}
	}
`;

export const NavMenu = styled.ul`
	display: flex;
	align-items: center;
	list-style: none;

	text-align: center;

	@media screen and (max-width: 960px) {
		display: flex;

		background: rgba(0, 0, 0, 0.9);
		backdrop-filter: blur(10px);
		flex-direction: column;
		width: 100%;
		height: 100vh;
		position: absolute;

		top: 50px;

		left: ${({ click }) => (click ? "0px" : "-100%")};
		/* opacity:1; */
		transition: left 0.5s ease;
	}

	@supports not (
		(-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))
	) {
		/* CSS to provide fallback alternative for Firefox and similar browsers */
		& {
			background-color: rgba(0, 0, 0, 1);
		}
	}
`;

export const NavItem = styled.li`
	display: flex;
	align-items: center;
	height: 80px;

	@media screen and (max-width: 960px) {
		width: 100%;

		&hover {
			border: none;
		}
	}
`;

export const NavTitle = styled.a`
	display: flex;
	align-items: center;
	color: #fff;
	background: transparent;
	box-sizing: border-box;
	text-decoration: none;
	margin-top: 18px;
	margin-right: 5px;
	padding: 0rem 1rem 0rem 1rem;
	height: 40px;
	width: 100%;
	font-size: 1.2rem;

	/* border-bottom: 2px solid transparent; */
	border-radius: 10px;
	transition: background 0.5s ease;

	&:hover {
		/* border-bottom: 2px solid black; */
		background: #d58936;
		text-decoration: none;
		color: #fff;
		cursor: pointer;
	}

	@media screen and (max-width: 960px) {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;

		&:hover {
			text-decoration: none;
			background: none;
			color: #d58936;

			transition: all 0.5s ease;
		}
	}
`;

export const NavLinks = styled(Link)`
	display: flex;
	align-items: center;
	color: #fff;
	background: transparent;
	box-sizing: border-box;
	text-decoration: none;
	margin-top: 18px;
	margin-right: 5px;
	padding: 0rem 1rem 0rem 1rem;
	height: 40px;
	width: 100%;

	/* border-bottom: 2px solid transparent; */
	border-radius: 10px;
	transition: background 0.5s ease;

	&:hover {
		/* border-bottom: 2px solid black; */
		background: #d58936;
		text-decoration: none;
		color: #fff;
	}

	@media screen and (max-width: 960px) {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;

		&:hover {
			text-decoration: none;
			background: none;
			color: #d58936;

			transition: all 0.5s ease;
		}
	}
`;

export const NavItemBtn = styled.li`
	@media screen and (max-width: 960px) {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
`;

export const NavBtnLink = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	text-decoration: none;
	padding: 50px 16px;
	height: 100%;
	width: 100%;
	border: none;
	outline: none;
`;
