import styled, { keyframes } from "styled-components";
import { ArrowBack } from "@styled-icons/boxicons-regular/ArrowBack";

export const Back = styled(ArrowBack)`
	color: black;
	width: 25px;
`;

export const BackButton = styled.button`
	color: black;
	background-color: transparent;
	z-index: 5;
	position: absolute;
	top: 100px;
	/* bottom: 0px; */
	left: 40px;
	outline: none;
	border: none;
	padding: 2px 2px;
	border-radius: 10px;

	transition: all 0.3s ease-in-out;

	&:hover {
		background-color: silver;
	}
	&:focus {
		outline-color: transparent;
		outline-style: none;
	}
`;

export const Span = styled.span`
	color: blue;
`;

export const HigherContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
	background: linear-gradient(#ffffff, #ffe9d0);
	margin-top: 15px;
`;

export const InfoPlanContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	@media screen and (max-width: 500px) {
		flex: 1;
	}
`;

export const Title = styled.h1`
	font-size: 3.5rem;
	line-height: 1.1;
	z-index: 2;
	/* color: #fff; */
	padding-top: 50px;
	padding-left: 30px;
	padding-right: 30px;
	margin: -2px 0px 0px 0px;

	/* background-color: #1c1c1c; */
	/* color: whitesmoke; */
	border-radius: 0%;
	padding-bottom: 50px;
	font-weight: 300;
	line-height: 1.2;
	text-align: center;
	/* background: -webkit-linear-gradient(#8b0000, #8a2be2);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent; */

	@media screen and (max-width: 968px) {
		padding-bottom: 65px;
		/* max-width: 300px; */
		font-size: 40px;
		margin-bottom: 30px;
		padding-bottom: 0;
	}
`;

export const DescDetails = styled.h2`
	font-size: 1.5rem;
	z-index: 5;
	font-weight: 600;
	text-align: center;
	color: #000;
`;

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const FormWrapper = styled.div`
	display: flex;
	background-color: whitesmoke;
	border-radius: 10px;
	flex: 0.75;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

	@media screen and (max-width: 700px) {
		flex: 1;
	}

	padding: 10px 10px;
	margin: 10px 10px;
`;
