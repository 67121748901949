import React from "react";
import { DropdownItem } from "./DropdownItem";
import { DropdownMobileCont, Text } from "./Dropdown.element";

export const DropdownMobile = ({
	show,
	setShow,
	click,
	setClick,
	abrir,
	setAbrir,
}) => {
	const handleActionNavBar = () => {
		setAbrir(false);
		setShow(!show);
		setClick(!click);
	};

	return (
		<>
			<DropdownMobileCont onClick={handleActionNavBar} show={show}>
				<DropdownItem destiny={"/planes-hogar"}>
					<Text>Plan Hogar</Text>
				</DropdownItem>
				<DropdownItem destiny={"/planes-ultra"}>
					<Text>Plan Ultra</Text>
				</DropdownItem>
				<DropdownItem destiny={"/planes-corporativos"}>
					<Text>Plan Corporativo</Text>
				</DropdownItem>
			</DropdownMobileCont>
		</>
	);
};
