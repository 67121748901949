import React, { useEffect, useRef, useState } from "react";
import { useForm } from "./../../hooks/useForm";
import Swal from "sweetalert2";
import validator from "validator";

import { estadoValidacion } from "./../../helper/validaciones";
import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
import { fetchSinTokenAxios } from "../../helper/fetchv1";

export const PlanesCorporativos = ({ location, history }) => {
	//mejoras a componentes stilizados y mejroa de codigo pendiente
	const [show, setShow] = useState(false);

	const submit = useRef();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [location]);

	const [startValidating, setStartValidating] = useState({
		startName: false,
		startMail: false,
		startCelular: false,
		startCedula: false,
		startDireccion: false,
	});

	const { startName, startMail, startCedula, startCelular, startDireccion } =
		startValidating;

	const [error, setError] = useState({
		errorName: false,
		errorMail: false,
		errorCelular: false,
		errorCedula: false,
		errorDireccion: false,
	});

	const { errorCedula, errorCelular, errorMail, errorName, errorDireccion } =
		error;

	const { formValues, handleInputChange, reset } = useForm({
		name: "",
		cedula: "",
		mail: "",
		celular: "",
		direccion: "",
	});
	const { name, cedula, mail, celular, direccion } = formValues;

	const startValidateName = () => {
		setStartValidating({ ...startValidating, startName: true });
	};

	const startValidateEmail = () => {
		setStartValidating({ ...startValidating, startMail: true });
	};

	const startValidateCelular = () => {
		setStartValidating({ ...startValidating, startCelular: true });
	};

	const startValidateCedula = () => {
		setStartValidating({ ...startValidating, startCedula: true });
	};
	const startValidateDireccion = () => {
		setStartValidating({ ...startValidating, startDireccion: true });
	};

	useEffect(() => {
		if (startName === true) {
			if (name.trim().length > 0) {
				setError((prevState) => ({ ...prevState, errorName: false }));
			} else if (name.trim().length === 0) {
				setError((prevState) => ({ ...prevState, errorName: true }));
			}
		}

		if (startMail === true) {
			if (validator.isEmail(mail)) {
				setError((prevState) => ({ ...prevState, errorMail: false }));
			} else {
				setError((prevState) => ({ ...prevState, errorMail: true }));
			}
		}
		if (startCelular === true) {
			if (validator.isInt(celular) && celular.trim().length > 5) {
				setError((prevState) => ({ ...prevState, errorCelular: false }));
			} else if (celular.trim().length === 0) {
				setError((prevState) => ({ ...prevState, errorCelular: true }));
			}
		}
		if (startCedula === true) {
			if (validator.isInt(cedula) && cedula.trim().length === 10) {
				setError((prevState) => ({ ...prevState, errorCedula: false }));
			} else {
				setError((prevState) => ({ ...prevState, errorCedula: true }));
			}
		}
		if (startDireccion === true) {
			if (direccion.trim().length > 0) {
				setError((prevState) => ({ ...prevState, errorDireccion: false }));
			} else if (direccion.trim().length === 0) {
				setError((prevState) => ({ ...prevState, errorDireccion: true }));
			}
		}
	}, [
		name,
		mail,
		celular,
		cedula,
		direccion,
		setError,
		startName,
		startCedula,
		startCelular,
		startMail,
		startDireccion,
	]);

	const validations = () => {
		let result;

		if (
			startCedula &&
			startCelular &&
			startMail &&
			startName &&
			startDireccion
		) {
			if (
				!errorCedula &&
				!errorCelular &&
				!errorMail &&
				!errorName &&
				!errorDireccion
			) {
				result = true;
				return result;
			}
		} else {
			result = false;
			return result;
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setShow(true);

		const result = validations();

		if (result) {
			console.log(formValues);
		} else {
			setShow(false);
			return Swal.fire("Error", "Completa todos los campos ", "error");
		}

		try {
			const resp = await fetchSinTokenAxios(
				"email/contractmail",
				{ ...formValues, plan: "Plan Corporativo" },
				"POST"
			);

			// const body = await resp.json();

			if (resp.data.ok) {
				setShow(false);
				Swal.fire("OK", "Correo Enviado, te contactaremos pronto!", "success");
				reset();
				setStartValidating({
					startName: false,
					startMail: false,
					startCelular: false,
					startCedula: false,
				});
				setError({
					errorName: false,
					errorMail: false,
					errorCelular: false,
					errorCedula: false,
				});
			} else {
				setShow(false);
				return Swal.fire("Error", "Intenta Nuevamente", "error");
			}
		} catch (error) {
			setShow(false);

			return Swal.fire("Error", "Sin conexion", "error");
		}
	};

	return (
		<>
			<Helmet>
				<title>Planes Corporativos | XfinityNet</title>
				<meta name="description" content="Planes Ultra" />
			</Helmet>
			<div className="flex-wrapper bgpricecorp">
				<Fade>
					<div className="container">
						<div className="row row-header justify-content-center mt-5">
							<div className="col-12 col-sm-12">
								<h1 className="text-center textoprice"> Planes Corporativos</h1>
								<h2 className="text-center textoprice"> Compartición 1:1</h2>
							</div>
						</div>

						<div className="row justify-content-center">
							<div className="col-12 col-sm-12 align-self-center">
								<p className="lead text-center textoprice">
									Manten tu negocio conectado con el mundo! Para contratar un
									plan corporativo, comunicate con nosotros y te ayudaremos
								</p>
							</div>
						</div>
					</div>
				</Fade>

				<div className="container">
					<div className="row mt-4 justify-content-center ">
						<div className="col-12 col-sm-6">
							<Fade triggerOnce={true}>
								<div className="row mt-1 justify-content-center">
									<div className="card ">
										<div className="row-12 mt-2">
											<Fade>
												<div className="card-body text-center">
													{" "}
													<i className="fas fa-phone-alt"></i>
													<strong> Call Center:</strong> +593988239514
												</div>
											</Fade>
										</div>
										<div className="row-12 mt-2 ">
											<Fade>
												<div className="card-body text-center ">
													<i className="fab fa-whatsapp"></i>{" "}
													<strong> Servicio Tecnico:</strong> +593963695305
												</div>
											</Fade>
										</div>
									</div>
								</div>
							</Fade>
						</div>

						<div className="col-12 col-sm-6 ">
							<Fade>
								<div className="card mb-2 shadow-sm">
									<div className="card-header text-center">
										<h4 className="my-0 font-weight-normal">
											{" "}
											<i className="fas fa-info-circle"></i> Ingresa tus datos:
										</h4>
									</div>

									<div className="card-body">
										<form onSubmit={handleSubmit}>
											<div className="form-group mt-2 ">
												<label htmlFor="Nombre">Nombre</label>
												<input
													name="name"
													className={`form-control ${
														errorName && "is-invalid"
													} ${
														estadoValidacion(startName, errorName) === 1 &&
														"is-valid"
													}`}
													value={name}
													onChange={handleInputChange}
													onFocus={startValidateName}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="Nombre">Cedula</label>
												<input
													name="cedula"
													className={`form-control ${
														errorCedula && "is-invalid"
													} ${
														estadoValidacion(startCedula, errorCedula) === 1 &&
														"is-valid"
													}`}
													value={cedula}
													onChange={handleInputChange}
													onFocus={startValidateCedula}
												/>
												{errorCedula === true && (
													<div className="invalid-feedback">
														Debe contener 10 digitos
													</div>
												)}
											</div>
											<div className="form-group">
												<label htmlFor="Nombre">Email</label>
												<input
													name="mail"
													className={`form-control ${
														errorMail && "is-invalid"
													} ${
														estadoValidacion(startMail, errorMail) === 1 &&
														"is-valid"
													}`}
													value={mail}
													onChange={handleInputChange}
													onFocus={startValidateEmail}
												/>
												{errorMail === true && (
													<div className="invalid-feedback">
														Introduce un email válido
													</div>
												)}
											</div>
											<div className="form-group">
												<label htmlFor="Nombre">Celular</label>
												<input
													name="celular"
													className={`form-control ${
														errorCelular && "is-invalid"
													} ${
														estadoValidacion(startCelular, errorCelular) ===
															1 && "is-valid"
													}`}
													value={celular}
													onChange={handleInputChange}
													onFocus={startValidateCelular}
												/>
											</div>

											<div className="form-group">
												<label htmlFor="direccion">Direccion</label>
												<input
													name="direccion"
													className={`form-control ${
														errorDireccion && "is-invalid"
													} ${
														estadoValidacion(startDireccion, errorDireccion) ===
															1 && "is-valid"
													}`}
													value={direccion}
													onChange={handleInputChange}
													onFocus={startValidateDireccion}
												/>
											</div>

											<button
												type="submit"
												className="btn btn-block btn-secondary mb-3"
												disabled={show}
												ref={submit}
											>
												{show && (
													<span
														className="spinner-border spinner-border-sm mr-1"
														role="status"
														aria-hidden="true"
													></span>
												)}
												Contratar
											</button>
										</form>
									</div>
								</div>
							</Fade>
						</div>

						{/* div de arriba es del formulario */}
					</div>
				</div>
			</div>
		</>
	);
};
