import React from "react";

import { NavItem, NavLinks, NavTitle } from "./Navbar.elements";

export const NavItems = ({
	children,
	abrir,
	setAbrir,
	setClick,
	setShow,
	show,
	mobile,
}) => {
	const handleDrop = () => {
		if (!mobile) {
			setAbrir(!abrir);
		}
		if (mobile) {
			setShow(!show);
		}
	};

	return (
		<div onClick={handleDrop}>
			<NavItem>
				<NavTitle>Planes</NavTitle>
				<div onClick={() => setClick(false)}>{abrir && children}</div>
			</NavItem>
		</div>
	);
};
