import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import {
	InfoSection,
	MainDashBoard,
	SecondaryDashBoard,
} from "../../components";
import {
	homeItemsOne,
	homeItemsThree,
	homeItemsTwo,
	homeObjOne,
	homeObjTwo,
	mainObjOne,
} from "./Data";

import {
	SecContent,
	Title,
	Wrapper,
} from "../../components/HomeScreen/SecondaryDashBoard.elements";
import { useNavigate } from "react-router-dom";

export const HomePage = () => {
	// const [altura, setAltura] = useState(0);
	// const parentRef = useRef(null);

	// useEffect(() => {
	// 	window.scrollTo({ top: 0, behavior: "auto" });

	// }, [location]);

	// const handleResize = () => {
	// 	if (parentRef.current) {
	// 		setAltura(parentRef.current.offsetHeight);
	// 	}
	// };
	// useEffect(() => {
	// 	window.addEventListener("resize", handleResize);
	// 	return () => window.removeEventListener("resize", handleResize);
	// }, []);

	// useLayoutEffect(() => {
	// 	handleResize();
	// }, []);

	const navigate = useNavigate();

	const [offsetY, setOffsetY] = useState(0);

	const handleScroll = () => setOffsetY(window.pageYOffset);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		// console.log(offsetY);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		navigate("/", { replace: true });
	}, [navigate]);

	return (
		<>
			<Helmet>
				<title>XfinityNet - Internet Fibra Optica</title>
				<meta
					name="description"
					content="XfinityNet tu proveedor de servicios de Internet de alta velocidad
Cuando tu conexión lo es todo, es porque tienes XfinityNet"
				/>
			</Helmet>
			<MainDashBoard {...mainObjOne} />

			<SecContent>
				<Title>Nuestro Compromiso</Title>

				<Wrapper offsetY={offsetY}>
					<SecondaryDashBoard {...homeItemsOne} />
					<SecondaryDashBoard {...homeItemsTwo} />
					<SecondaryDashBoard {...homeItemsThree} />
				</Wrapper>
			</SecContent>

			<InfoSection {...homeObjOne} />
			<InfoSection {...homeObjTwo} />
		</>
	);
};
