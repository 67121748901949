import styled, { keyframes } from "styled-components";

const scroll = keyframes`

	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
`;

export const SecContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	max-width: 100%;
	width: 100vw;

	flex: 1;
	padding: 10px 10px 10px 10px;
	/* background: ${({ lightBg }) =>
		lightBg ? "rgba(241, 242, 244, 1)" : "rgba(241, 242, 244, 1)"}; */

	background: #1b1b1b;
	@media screen and (max-width: 1134px) {
		/* max-width: 300px; */
		/* height: 800px; */
	}

	@media screen and (max-width: 768px) {
		/* max-width: 300px; */
		/* height: 1100px; */
	}

	@media screen and (max-width: 480px) {
		/* max-width: 300px; */
		/* height: 1100px; */
	}
`;

export const Wrapper = styled.div`
	z-index: 3;
	max-width: 100vw;
	/* margin: 0 auto; */
	/* display: grid;
	grid-template-columns: 1fr 1fr 1fr; */
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	grid-gap: 30px;
	padding: 0px 20px;
	transform: ${({ offsetY }) =>
		offsetY ? `translateY(-${offsetY * 0.1}px)` : `translateY(0px)`};
	@media screen and (max-width: 1000px) {
		/* max-width: 300px; */
		/* grid-template-columns: 1fr 1fr; */
	}

	@media screen and (max-width: 808px) {
		/* max-width: 300px; */
		/* grid-template-columns: 1fr; */
		padding: 0 20px;
	}
`;

export const Card = styled.div`
	z-index: 3;
	background: #000;
	display: flex;
	flex-direction: column;
	justify-content: flex-baseline;
	align-items: center;
	border-radius: 10px;
	height: 100%;
	padding: 5px 5px;
	/* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
	transition: all 0.2s ease-in-out;

	@media screen and (min-width: 1000px) {
		/* max-width: 300px; */
		height: 360px;
		width: 350px;
	}

	@media screen and (max-width: 999px) {
		/* max-width: 300px; */
		height: 360px;
		width: 340px;
	}

	&:hover {
		/* transform: scale(1.02); */

		@media screen and (min-width: 1000px) {
			cursor: default;
			/* max-width: 300px; */
			background: #000;

			transition: all 0.2s ease-in-out;
			/* cursor: pointer; */

			p {
				color: white;
				opacity: 1;
				bottom: 50%;
				transform: translate3d(-50%, 20%, 0);
				transition: all 0.2s ease-out;
			}
		}
	}
`;

export const Auxiliary = styled.div`
	transition: 0.5s cubic-bezier(0.6, 0.4, 0, 1);

	@media screen and (min-width: 1000px) {
		/* max-width: 300px; */
		transform: translateY(25%);
		${Card}:hover & {
			opacity: 0;
		}
	}

	text-align: center;
`;

export const Img = styled.img`
	height: 160 px;
	width: 160px;
	margin-bottom: 10px;
	vertical-align: middle;
	display: inline-block;

	/* max-height: 600px; */
`;

export const Title = styled.h1`
	z-index: 3;
	font-size: 3rem;
	color: #81582c;
	font-weight: 600;
	/* rgba(241, 242, 244, 1) */
	padding: 60px;
	text-align: center;

	@media screen and (max-width: 480px) {
		font-size: 2rem;
	}
`;

export const SecondTitle = styled.h2`
	font-size: 3rem;
	color: #000;
	padding: 10px 10px;
	text-align: center;
	font-weight: 500;
	/* background: -webkit-linear-gradient(#81582c, #8a2be2); */
	background: linear-gradient(to right, #81582c, #696969);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;

	@media screen and (max-width: 900px) {
		font-size: 2.5rem;
		font-weight: 600;
	}
`;

export const Subtitle = styled.p`
	text-align: center;
	font-weight: 550;
	font-size: 1rem;
	color: #dcdcdc;

	pointer-events: none;
	@media screen and (min-width: 1000px) {
		font-size: 1.5rem;
		position: relative;
		left: 50%;
		bottom: 0;
		transform: translate3d(-50%, 100%, 0);
		transition: 0.5s cubic-bezier(0.6, 0.4, 0, 1);
		opacity: 0;
		line-height: 1.4;
		letter-spacing: -0.035em;
	}

	@media screen and (max-width: 999px) {
		font-size: 15px;
	}
`;
