import React from "react";
import { planesHogar } from "../../data/data";
import {
	Wrapper,
	DescPlans,
	PlansContainer,
	Title,
	Bg,
	Layer,
	DescContainer,
} from "./Plans.elements";
import { Cards } from "./Cards";

export const Plans = ({
	mainTitle,
	sub1,
	sub2,
	sub3,
	planType,
	src,
	tipo,
	hogar,
}) => {
	return (
		<>
			<PlansContainer>
				<Layer />
				<Bg src={src} />
				<Title hogar={hogar}>{mainTitle}</Title>
				<DescContainer>
					<DescPlans>{sub1}</DescPlans>
					<DescPlans>{sub2}</DescPlans>
					<DescPlans>{sub3}</DescPlans>
				</DescContainer>
				<Wrapper>
					{planType.map((obj) => (
						<Cards
							title={obj.title}
							velocidad={obj.velocidad}
							precio={obj.precio}
							id={obj.id}
							desc={obj.desc}
							key={obj.id}
							tipo={tipo}
							hogar={hogar}
						/>
					))}
				</Wrapper>
			</PlansContainer>
		</>
	);
};
