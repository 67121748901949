import React from 'react';
import svg from '../../images/xfinitylogoalt.svg';
const NavIconImg = ({children, className}) => (



    <img
      src={svg} className={className}
      alt= "logo"
    >
    
    </img>
  );
  
  export default NavIconImg;