import styled from "styled-components";

import { InfoCircleFill } from "@styled-icons/bootstrap/InfoCircleFill";

export const Info = styled(InfoCircleFill)`
	color: black;
	width: 25px;
`;

export const Span = styled.span`
	vertical-align: baseline;
`;

export const FormLabel = styled.label`
	font-weight: 600;
	width: 80px;
	text-align: left;

	@media screen and (min-width: 1000px) {
		flex: 1;
	}
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const InputWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0px 0px 0px;
	width: 100%;
	@media screen and (max-width: 620px) {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		/* width: 80%; */
	}
`;

export const ImgWrapper = styled.div`
	/* transform:${({ inView }) =>
		inView ? "translateX(0px)" : "translateX(50%)"} ; */
	transition: transform 0.7s ease-in;
	max-width: 1000px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const Img = styled.img`
	padding-right: 0;
	border: 0;
	max-width: 30%;
	vertical-align: middle;
	display: inline-block;
	border-radius: 10px;

	/* max-height: 600px; */
`;

export const Desc = styled.h1`
	font-size: 2rem;
	color: #000;
	margin: 0 0 0 0;

	text-align: left;
	font-weight: 600;
`;

export const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* width: 100%; */
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 40px 40px;
	width: 100%;
	height: 100%;

	@media screen and (max-width: 769px) {
		flex-direction: column;
		margin-top: 0;
		margin-bottom: 0;
		padding: 0 0 0 0;
		/* width: 80%; */
	}
`;
export const FormInput = styled.input`
	padding: 10px 10px 10px 10px;
	border-radius: 8px;
	/* margin-top: 40px; */
	border: none;
	font-size: 16px;
	height: 60px;
	width: 80%;
	/* flex: 1; */

	/* width: 700px; */

	border: 1px solid #ced4da;
	border-color: ${({ check, error }) =>
		check ? (error === true ? "#dc3545" : "#28a745") : "#ced4da"};

	/* 28a745 */

	/* "#dc3545" */
	&::placeholder {
		color: dimgray;
	}

	&:focus {
		outline-color: ${({ check, error }) =>
			check
				? error === true
					? "rgba(220, 53, 69, 0.9)"
					: "rgba(40, 167, 69, 1)"
				: "rgba(0, 0, 0, 0.9)"};
	}

	@media screen and (max-width: 820px) {
		width: 100%;
	}
`;

export const TextForm = styled.div`
	font-size: 20px;
`;
