interface DataPlanes {
	title: string;
	velocidad: string;
	precio: string;
	id: string;
	desc: string;
}

export const getById = (
	planId: string,
	planType: Array<DataPlanes>
): Array<string> | any => {
	try {
		if (planId && planType) {
			return planType.find((planes: DataPlanes) => planId === planes.id);
		}
	} catch (error) {
		return "no results";
	}
};
