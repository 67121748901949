import axios, { Method } from "axios";
import { useEffect, useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";

export const useFetch = (endpoint: string, type: Method) => {
	// const baseURL = process.env.REACT_APP_API_URL;
	const controller = new AbortController();
	const baseURL = process.env.REACT_APP_API_URL;
	const isMounted = useRef(true);
	const [progress, setProgress] = useState(0);
	const [load, setLoad] = useState(false);
	const url = `${baseURL}/${endpoint}`; //localhost:400/api/auth

	const fetch = useCallback(
		async (data?: object, extraHeaders: object = {}) => {
			setLoad(true);
			let resp;
			if (type === "get") {
				try {
					resp = await axios({
						method: type,
						url: url,
						signal: controller.signal,
						headers: {
							"Content-type": "application/json",
							...extraHeaders,
						},
						onDownloadProgress: (progressEvent) => {
							console.log(progressEvent);

							if (progressEvent.lengthComputable) {
								const { loaded, total } = progressEvent;
								let percent = Math.floor((loaded * 100) / total);
								if (isMounted.current) {
									setProgress(percent);
								}
							}
						},
					});

					if (resp) {
						console.log(resp?.data);
					}

					if (!isMounted.current) {
						throw new Error("Required");
					}
					setLoad(false);
					if (isMounted.current) {
						setLoad(false);
					}
					return resp;
				} catch (error) {
					if (isMounted.current) {
						setLoad(false);
					}

					if (error instanceof Error) {
						console.log(error.message);
					}
					console.log("error");
				}
			} else {
				try {
					resp = await axios({
						method: type,
						url: url,
						signal: controller.signal,
						data: data,
						headers: {
							"Content-type": "application/json",
						},
						onUploadProgress: (progressEvent) => {
							if (progressEvent.lengthComputable) {
								const { loaded, total } = progressEvent;
								let percent = Math.floor((loaded * 100) / total);
								if (isMounted.current) {
									setProgress(percent);
								}
							}
						},
					});
					if (isMounted.current) {
						setLoad(false);
					}
					return resp;
				} catch (error) {
					if (isMounted.current) {
						setLoad(false);
					}

					if (error instanceof Error) {
						console.log(error.message);
					}
				}
			}
		},
		[url, type]
	);

	useEffect(() => {
		console.log("iniciando peticion....");

		return () => {
			controller.abort();
			isMounted.current = false;
			console.log("componente desmontado");
		};
	}, [url]);

	return { progress, fetch, load };
};
