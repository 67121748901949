import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { Container } from "../../globalStyles";
import { Fade } from "react-awesome-reveal";
import {
	InfoSec,
	InfoRow,
	InfoColumn,
	TextWrapper,
	Heading,
	Subtitle,
	TopLine,
	ImgWrapper,
	Img,
} from "./InfoSection.elements";

export const InfoSection = ({
	lightBg,
	imgStart,
	topLine,
	description,
	headLine,
	lightText,
	lightTopLine,
	lightTextDesc,
	start,
	img,
	alt,
	location,
}) => {
	const { ref, inView } = useInView({
		triggerOnce: true,
		/* Optional options */
		threshold: [0.54, 0.75, 0.5, 0.75, 1],
	});

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "auto" });
		return () => {};
	}, [location]);

	return (
		<>
			<InfoSec lightBg={lightBg} ref={ref}>
				<Container>
					<Fade direction="left" triggerOnce="true">
						<InfoRow imgStart={imgStart}>
							<InfoColumn>
								<TextWrapper>
									<TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
									<Heading lightText={lightText}>{headLine}</Heading>
									<Subtitle lightTextDesc={lightTextDesc}>
										{description}
									</Subtitle>
									<Link to="/">
										{/* <Button big fontBig primary={primary}>
										{buttonLabel}
									</Button> */}
									</Link>
								</TextWrapper>
							</InfoColumn>
							<InfoColumn>
								<ImgWrapper start={start}>
									<Img src={img} alt={alt} />
								</ImgWrapper>
							</InfoColumn>
						</InfoRow>
					</Fade>
				</Container>
			</InfoSec>
		</>
	);
};
