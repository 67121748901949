import React, { useState, useEffect } from "react";

import {
	Nav,
	NavBarContainer,
	NavIcon,
	NavLogo,
	Burger,
	NavMenu,
	NavItem,
	NavLinks,
} from "./Navbar.elements";

import { IconContext } from "react-icons/lib";
import { NavItems } from "./NavItems";
import { Dropdown } from "..";
import { DropdownMobile } from "../Dropdown/DropdownMobile";

export const Navbar = () => {
	// console.log(process.env)
	const [click, setClick] = useState(false);
	// const [button, setButton] = useState(true);
	const [abrir, setAbrir] = useState(false);
	const [show, setShow] = useState(false);
	const [mobile, setMobile] = useState(false);

	// const [test, setTest] = useState(0);

	const [width, setWidth] = useState(window.innerWidth);

	// const handleOnClick = () => {
	// 	if (width < 960) {
	// 		setClick(!click);
	// 	}
	// };
	const handleResize = () => {
		setWidth(window.innerWidth);
	};

	const handleOpen = () => {
		setAbrir(false);
		if (width < 960) {
			setClick(!click);
		}
	};

	// const handleExtra = () => {
	// 	setShow(true);

	//     console.log(show);

	// };

	const showButton = () => {
		if (window.innerWidth <= 960) {
			setMobile(true);

			console.log("mobile");

			// setButton(false);
		} else {
			setMobile(false);
			// setButton(true);
		}
	};

	// const handleSetTest = () =>{
	//     setTest((test)=> test +1 );
	// }

	useEffect(() => {
		showButton();
		window.addEventListener("resize", handleResize);
		window.addEventListener("resize", showButton);
		if (click) {
			document.body.style.overflow = "hidden";
		} else if (!click) {
			document.body.style.overflow = "unset";
		}
		return () => {
			window.removeEventListener("resize", showButton);
			window.removeEventListener("resize", handleResize);
		};
	}, [width, click]);

	return (
		<>
			<IconContext.Provider value={{}}>
				<Nav click={click}>
					<NavBarContainer>
						<NavLogo to="/">
							<NavIcon />
						</NavLogo>
						{/* <MobileIcon onClick={handleClick} >
                   {click ? <FaTimes/> : <FaBars/>}
               </MobileIcon> */}

						<Burger setStatus={setClick} status={click} />

						<NavMenu click={click} width={width}>
							<NavItem onClick={handleOpen}>
								<NavLinks to="/">Inicio</NavLinks>
							</NavItem>
							{/* ojo que este es un componente para dropdowns */}
							<NavItems
								text={"Planes"}
								abrir={abrir}
								setAbrir={setAbrir}
								setClick={setClick}
								click={click}
								setShow={setShow}
								show={show}
								mobile={mobile}
							>
								{!mobile && <Dropdown />}
							</NavItems>

							{mobile && (
								<DropdownMobile
									setAbrir={setAbrir}
									setClick={setClick}
									click={click}
									setShow={setShow}
									show={show}
								/>
							)}

							<NavItem onClick={handleOpen}>
								<NavLinks to="/contacto">Contacto</NavLinks>
							</NavItem>
							<NavItem onClick={handleOpen}>
								<NavLinks to="/speedtest">SpeedTest</NavLinks>
							</NavItem>

							<NavItem onClick={handleOpen}>
								<NavLinks to="/cobertura">Cobertura</NavLinks>
							</NavItem>

							{/* <NavItemBtn>
                        {button ? (
                            <NavBtnLink to='/sign-up'>
                                <Button primary>
                                    Sign Up
                                </Button>
                            </NavBtnLink>)
                            :
                            (
                                <NavBtnLink to='/sign-up'>
                                    <Button fontBig primary>
                                        Sign Up
                                    </Button>
                                </NavBtnLink>
                            )

                        }
                    </NavItemBtn> */}
						</NavMenu>
					</NavBarContainer>
				</Nav>
			</IconContext.Provider>
		</>
	);
};
