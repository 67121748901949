import React from "react";
import { Navigate } from "react-router-dom";

export const PrivateRoutes = ({ isLogged, role, children }) => {
	if (isLogged && role === "admin") {
		return children;
	} else {
		return <Navigate to="/" replace={true} />;
	}
};
