import svg1 from "../../images/vision.jpg";
// import svg2 from '../../images/imag1.svg'
import svg3 from "../../images/fibra.jpg";
import calidad from "../../images/calidad.svg";
import comparticion from "../../images/comparticion.svg";
import simetria from "../../images/simetria.svg";

export const homeObjOne = {
	lightBg: false,
	imgStart: "1",
	topLine: "Misión",
	description:
		"Xfinitynet es una empresa dedicada a ofrecer servicios de internet por fibra óptica en la ciudad de portoviejo y zonas aledañas con la finalidad de satisfacer la urgente necesidad de este servicio debido al nuevo esquema de vida que se vive actualmente en el pais y en todo el mundo.",
	headLine: "Servicio Confiable y Consistente",
	lightText: true,
	lightTopLine: true,
	lightTextDesc: true,
	buttonLabel: "",
	primary: true,
	start: "1",
	img: svg1,
	alt: "Imagen Ingenieria",
};
export const homeObjTwo = {
	lightBg: true,
	imgStart: "",
	topLine: "Visión",
	description:
		"Ser una empresa reconocida por la calidad del servicio de internet por fibra óptica que se ofrece. Ampliar el radio de cobertura para llegar a lugares que no cuenten con este servicio y brindar soluciones a la colectividad en este sentido.",
	headLine: "Conectando a la comunidad",
	lightText: false,
	lightTopLine: false,
	lightTextDesc: false,
	buttonLabel: "Get Started",
	primary: true,
	start: "",
	img: svg3,
	alt: "Imagen Ingenieria",
};

export const mainObjOne = {
	lightBg: false,
	imgStart: "1",
	heading: "Di adiós a los problemas de conexión",
	sub1: "Selecciona un tipo de plan para empezar",
	sub2: "Servicio de OTT Streaming",
	lightText: true,
	lightTextDesc: true,
	buttonLabel: "Get Started",
	primary: false,
	alt: "Imagen Ingenieria",
	plan1: "Hogar",
	plan2: "Ultra",
	plan3: "Corporativo",
	servicio1: "OTT",
};

export const homeItemsOne = {
	img: calidad,
	secondtitle: "Calidad",
	desc: "Nuestro servicio de internet promete calidad y estabilidad mientras disfrutas del mejor contenido online",
	lightTextDesc: true,
};

export const homeItemsTwo = {
	img: simetria,
	secondtitle: "Simetría",
	desc: "Disfruta de la velocidad de tu plan cuando descargas o cuando subes contenido!",
	lightTextDesc: true,
};
export const homeItemsThree = {
	img: comparticion,
	secondtitle: "Compartición",
	desc: "Las comparticiones varian segun el plan que elijas, revisa nuestros planes y selecciona el mejor para tus necesidades",
	lightTextDesc: true,
};
