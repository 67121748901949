import family from "../../images/family.jpg";
import gaming from "../../images/gaming.jpg";
export const planesHogarDetails = {
	mainTitle: "Planes Hogar",
	sub1: "Los planes listados tienen compartición 2:1",
	sub2: "Costo de Instalación $25 + IVA",
	sub3: "Velocidad Simétrica",
	src: family,
	id: "planes-hogar",
};

export const planesUltraDetails = {
	mainTitle: "Planes Ultra",
	sub1: "Los planes listados tienen compartición 1:1",
	sub2: "Entretenimiento ilimitado",
	sub3: "Velocidad Simétrica",
	src: gaming,
	id: "planes-ultra",
};

export const planesHogar = [
	{
		title: "Plan Basic",
		velocidad: "20MB",
		precio: "$22,40",
		id: "planbasic",
		desc: "Hasta 6 dispositivos",
	},
	{
		title: "Plan Pro",
		velocidad: "35MB",
		precio: "$26,00",
		id: "planpro",
		desc: "Youtube HD",
	},

	{
		title: "Plan Blast",
		velocidad: "50MB",
		precio: "$30,00",
		id: "planblast",
		desc: "Youtube 4k HD",
	},
	{
		title: "Plan Extreme",
		velocidad: "100MB",
		precio: "$53,00",
		id: "planextreme",
		desc: "Entretenimiento ilimitado",
	},
];
export const planesUltra = [
	{
		title: "Plan Ultra H",
		velocidad: "20MB",
		precio: "$26,00",
		instalacion: "$50",
		id: "planultrah",
		desc: "Personal - Netflix 4k",
	},
	{
		title: "Plan Ultra S",
		velocidad: "35MB",
		precio: "$38,00",
		instalacion: "$45",
		id: "planultras",
		desc: "Netflix 4k - Gaming",
	},

	{
		title: "Plan Ultra X",
		velocidad: "70MB",
		precio: "$56,00",
		instalacion: "$40",
		id: "planultrax",
		desc: "Familia - Netflix 4k - Gaming - Streaming y más! ",
	},
];
