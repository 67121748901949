//styles for the footer
import { Link } from "react-router-dom";
import styled from "styled-components";
import {Whatsapp} from "@styled-icons/icomoon/Whatsapp";
import {Facebook} from "@styled-icons/bootstrap/Facebook";

export const WS = styled(Whatsapp)`
color: green;
width: 2rem;

`;

export const FB = styled(Facebook)`
color:#2374e1;
width: 2rem;
`;

export const FooterContainer = styled.div`


background-color: #ffe9d0;
padding: 3rem 0 2rem 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;


`;



export const FooterSuscription =styled.section`

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
margin-bottom: 6px;
padding: 24px;
color: #000;

`;

export const FooterSubHeading =styled.p`

margin-bottom: 8px;
font-size: 24px;
font-weight: 500;


`;



export const FooterSubText =styled.p`

margin-bottom: 14px;
font-size: 20px;
font-weight: 500;

`;


export const Form = styled.form`
display: flex;
justify-content: center;
align-items: center;
flex: 1;


@media screen and (max-width: 820px){
    flex-direction: column;
    width: 80%;
}


`;

export const FormInput = styled.input`
padding: 10px 25px 10px 10px;
border-radius: 8px;
margin-right: 20px;
margin-left: auto;
border:none;
font-size: 16px;
border:1px solid #000;
height:55px;

&::placeholder{
    color: dimgray
}

@media screen and (max-width: 820px){
    margin: 0 0 16px 0;
    width: 100%;
}



`;


export const FooterLinskContainer= styled.div`
width: 100%;
max-width: 1000px;
display: flex;
justify-content:center;
align-items: flex-start;

flex-wrap: wrap;


@media screen and (max-width:960px){
    padding-top: 22px;

}



`;

export const FooterLinksWrapper= styled.div`
display: flex;
justify-content:center;
align-items: center;
flex: 1;



@media screen and (max-width:960px){

    padding-right: 15px;
	padding-left: 15px;
	/* max-width: 50%; */
  
   
	/* display: flex; */
    /* max-width: 50%; */

   
    /* max-width: 50%; */
   
}

`;


export const Img = styled.img`
	width:80%;
    margin-bottom: 10px;
    
	vertical-align: middle;
	display: inline-block;

	/* max-height: 600px; */

    
@media screen and (max-width:960px){

width:200px;

}
`;

export const FooterLinksItems = styled.div`

display: flex;
flex-direction: column;
align-items: center;
margin: 16px;
text-align: center;
width: 160px;
box-sizing: border-box;
color: #000;


@media screen and (max-width:920px){
    margin:0;
    padding: 10px;
    width: 100%;
    
}
`;


export const FooterLinksTitle= styled.h2`
margin-bottom: 16px;
color: black;

`;

export const FooterLink= styled(Link)`
color: #000;
text-decoration: none;
margin-bottom: 0.5rem;




&:hover{
    color: #d58936;
    transition: 0.3s ease-out;
text-decoration: none;
}


`;

export const IconsWrapper = styled.div`

display: flex;
flex-wrap:wrap ;


`;

export const NormalLink = styled.a`
color: #000;
text-decoration: none;
margin-bottom: 0.5rem;
padding:0px 10px 0px 10px;

&:hover{
    color: #d58936;
    transition: 0.3s ease-out;
text-decoration: none;
}
`;



export const InfoBoxContainer = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-around;
align-items: center;


`;


export const DetailWrapper = styled.div`
display:flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-bottom: 10px;
`;


export const Heading= styled.h2`
color: #000;
font-size: 15px;
font-weight: 500;

`;

export const Text= styled.p`
color: #000;
font-size: 15px;
font-weight: 500;
text-align:center;
padding: 0px 0px 0px 0px;
@media screen and (max-width:720px){
    font-size: 12px;
    text-align:center;
  
    
}
`;