import React from "react";
import { DropdownContainer, Text } from "./Dropdown.element";
import { DropdownItem } from "./DropdownItem";

export const Dropdown = () => {
	return (
		<DropdownContainer>
			<DropdownItem destiny={"/planes-hogar"}>
				<Text>Plan Hogar</Text>
			</DropdownItem>
			<DropdownItem destiny={"/planes-ultra"}>
				<Text>Plan Ultra</Text>
			</DropdownItem>
			<DropdownItem destiny={"/planes-corporativos"}>
				<Text>Plan Corporativo</Text>
			</DropdownItem>
		</DropdownContainer>
	);
};
