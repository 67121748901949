import React from "react";
import { Form } from "..";
import {
	ContactContainer,
	Card,
	Title,
	TextCard,
	TextWrapper,
	Telephone,
	WA,
	HigherContainer,
	FormWrapper,
} from "./Contact.element";

export const Contact = () => {
	return (
		<>
			<HigherContainer>
				<ContactContainer>
					<Title>Elige tu método preferido para contactarnos</Title>

					<Card>
						<TextWrapper>
							<TextCard>
								<span>
									<Telephone />
								</span>
								+593988239514
							</TextCard>
						</TextWrapper>

						<TextWrapper>
							<TextCard>
								<span>
									<WA />
								</span>
								+593963695305
							</TextCard>
						</TextWrapper>
					</Card>
				</ContactContainer>
				<FormWrapper>
					<Form />
				</FormWrapper>
			</HigherContainer>
		</>
	);
};
