import React from "react";
import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import {
	Wrapper,
	Card,
	CardTitle,
	CardDesc,
	CardFooter,
	CardLinks,
} from "./Plans.elements";

export const Cards = ({ title, velocidad, precio, id, desc, tipo, hogar }) => {
	return (
		<>
			<Zoom triggerOnce={true}>
				<CardLinks to={`/${tipo}/${id}`}>
					<Card hogar={hogar} tipo={tipo}>
						<CardTitle>
							{`${title}
					${velocidad}`}
						</CardTitle>

						<CardDesc>{`${precio} / mes (incluye IVA)`}</CardDesc>
						<CardFooter>{desc}</CardFooter>
					</Card>
				</CardLinks>
			</Zoom>
		</>
	);
};
