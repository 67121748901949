import React, {memo} from 'react';

export const BurgerMenu = memo(({className, status, setStatus}) => {

  const handleOnClick = () => setStatus(!status)

  return (
    
      <div
        className={className}
        role="button"
        onClick={handleOnClick}
        
      >
        <i className={status===false ? 'closed' : 'open' }></i>
        <i className={status===false ? 'closed' : 'open' }></i>
        <i className={status===false ? 'closed' : 'open' }></i>
      </div>
  );
}
);
