//?Optimizacion, se puede mandar estas funciones de manera externa
export const estadoValidacion = (startValidation, error) => {
	let valid = 0;

	if (startValidation) {
		if (!error) {
			valid = 1;
			return valid;
		} else {
			valid = 2;
			return valid;
		}
	} else {
		return valid;
	}
};
