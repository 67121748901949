import React from "react";

import logo from "../../images/xfinitylogo2.svg";
import {
	FooterContainer,
	NormalLink,
	FooterSuscription,
	FooterSubHeading,
	FooterSubText,
	FooterLink,
	FooterLinksItems,
	FooterLinksTitle,
	FooterLinksWrapper,
	FooterLinskContainer,
	WS,
	FB,
	InfoBoxContainer,
	DetailWrapper,
	Heading,
	Text,
	IconsWrapper,
	Img,
} from "./Footer.element";

export const Footer = () => {
	return (
		<FooterContainer>
			<FooterSuscription>
				<FooterSubHeading>
					Contactános y se parte de esta nueva era
				</FooterSubHeading>
				<FooterSubText>Disfruta de un servicio de calidad!</FooterSubText>
				<Img src={logo} alt="XfinityNet" />
			</FooterSuscription>

			<FooterLinskContainer>
				<FooterLinksWrapper>
					<FooterLinksItems>
						<FooterLinksTitle>Links</FooterLinksTitle>
						<FooterLink to="/">Inicio</FooterLink>
						<FooterLink to="/contacto">Contacto</FooterLink>
						<FooterLink to="/speedtest">Speedtest</FooterLink>
						<FooterLink to="/cobertura">Cobertura</FooterLink>
					</FooterLinksItems>
				</FooterLinksWrapper>

				<FooterLinksWrapper>
					<FooterLinksItems>
						<FooterLinksTitle>Encuéntranos:</FooterLinksTitle>
						<InfoBoxContainer>
							<DetailWrapper>
								<Heading>Dirección:</Heading>
								<Text>
									Calle 15 de abril y callejón 29 de junio Manabi, Portoviejo
									Ecuador
								</Text>
							</DetailWrapper>
							<DetailWrapper>
								<Heading>Celular:</Heading>
								<Text>+593 98 823 9514</Text>
							</DetailWrapper>
							<DetailWrapper>
								<Heading>Email</Heading>
								<NormalLink href="mailto:xaviersolis@xfinitynet.net">
									<Text>{`xaviersolis@xfinitynet.net`}</Text>
								</NormalLink>
							</DetailWrapper>
						</InfoBoxContainer>
					</FooterLinksItems>
				</FooterLinksWrapper>

				<FooterLinksWrapper>
					<FooterLinksItems>
						<FooterLinksTitle>Redes Sociales</FooterLinksTitle>
						<IconsWrapper>
							<NormalLink
								href="https://wa.link/25urxr"
								target="_blank"
								rel="noopener noreferrer"
							>
								<WS />
							</NormalLink>
							<NormalLink
								href="https://www.facebook.com/XfinitynetEcuador"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FB />
							</NormalLink>
						</IconsWrapper>
					</FooterLinksItems>
				</FooterLinksWrapper>
			</FooterLinskContainer>
		</FooterContainer>
	);
};
