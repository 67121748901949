import React, { memo, useEffect, useRef } from "react";
import { Contact } from "../../components";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
export const ContactPage = memo(() => {
	const locationRef = useRef();

	let location = useLocation();

	useEffect(() => {
		// console.log(location);
		if (location.pathname != locationRef.current) {
			window.scrollTo({ top: 0, behavior: "smooth" });
			locationRef.current = location.pathname;
		}

		if (location.pathname === "/contacto") {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
	}, [location.key]);
	return (
		<>
			<Helmet>
				<title>Contacto | XfinityNet</title>
				<meta name="description" content="Contacto" />
			</Helmet>
			<Contact />
		</>
	);
});
