import React from "react";
import { Route, Routes } from "react-router-dom";
import { PrivateSettings } from "./PrivateSettings";
import { Welcome } from "./Welcome";

export const PrivateDashboard = () => {
	return (
		<>
			<Routes>
				<Route index element={<Welcome />} />
				<Route path="settings" element={<PrivateSettings />} />
			</Routes>
		</>
	);
};
