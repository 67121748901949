export const zona1 = [
	{ lat: -1.067809988, lng: -80.4503562 },
	{ lat: -1.07378226, lng: -80.443396985 },
	{ lat: -1.075620933, lng: -80.439611792 },
	{ lat: -1.069641268, lng: -80.440446183 },
	{ lat: -1.069305902, lng: -80.441949353 },
	{ lat: -1.067997372, lng: -80.44179352 },
	{ lat: -1.067611479, lng: -80.442097558 },
	{ lat: -1.066131728, lng: -80.441364499 },
	{ lat: -1.065890659, lng: -80.441995327 },
	{ lat: -1.065176377, lng: -80.442394576 },
	{ lat: -1.06510518, lng: -80.44344841 },
	{ lat: -1.063240152, lng: -80.4431287 },
	{ lat: -1.063261453, lng: -80.44444292 },
	{ lat: -1.061916403, lng: -80.446110691 },
	{ lat: -1.061416543, lng: -80.44702197 },
	{ lat: -1.063807333, lng: -80.45030398 },
	{ lat: -1.065565689, lng: -80.450486005 },
	{ lat: -1.065807948, lng: -80.449135301 },
	{ lat: -1.067809988, lng: -80.4503562 },
];

export const zona2 = [
	{ lat: -1.07508299, lng: -80.445188775 },
	{ lat: -1.072487098, lng: -80.445225385 },
	{ lat: -1.071646283, lng: -80.446120064 },
	{ lat: -1.072868493, lng: -80.446770819 },
	{ lat: -1.075362231, lng: -80.446601576 },
	{ lat: -1.075819416, lng: -80.447870154 },
	{ lat: -1.07367124, lng: -80.449032731 },
	{ lat: -1.075494687, lng: -80.451920913 },
	{ lat: -1.076837735, lng: -80.454559953 },
	{ lat: -1.079855018, lng: -80.453349671 },
	{ lat: -1.079470084, lng: -80.449677564 },
	{ lat: -1.078210973, lng: -80.448748954 },
	{ lat: -1.078134339, lng: -80.447355982 },
	{ lat: -1.077184276, lng: -80.446573212 },
	{ lat: -1.077164115, lng: -80.444122765 },
	{ lat: -1.076601667, lng: -80.44342706 },
	{ lat: -1.075393139, lng: -80.443771369 },
	{ lat: -1.07508299, lng: -80.445188775 },
];

export const zona3 = [
	{ lat: -1.073849652, lng: -80.443790289 },
	{ lat: -1.074613256, lng: -80.444282042 },
	{ lat: -1.075364093, lng: -80.44363407 },
	{ lat: -1.07694118, lng: -80.441777392 },
	{ lat: -1.077038078, lng: -80.443301508 },
	{ lat: -1.078270111, lng: -80.445235006 },
	{ lat: -1.078799488, lng: -80.44493569 },
	{ lat: -1.078609439, lng: -80.442892257 },
	{ lat: -1.081619897, lng: -80.441202911 },
	{ lat: -1.079774159, lng: -80.438624979 },
	{ lat: -1.077004182, lng: -80.439031706 },
	{ lat: -1.07546562, lng: -80.440004131 },
	{ lat: -1.074163525, lng: -80.443046148 },
	{ lat: -1.073849652, lng: -80.443790289 },
];

export const zona4 = [

{ lng:-80.4847766491902, lat:-1.060176358603015},
{ lng:-80.47683268255726,lat: -1.063625872334266},
{ lng:-80.47544924877973,lat: -1.067462461212895},
{ lng:-80.47280453322327,lat: -1.064267528321721},
{ lng:-80.47592683837883,lat: -1.055174850714273},
{ lng:-80.48285186228118,lat: -1.054793876289861},
{ lng:-80.4820972435248, lat:-1.051994208173518},
{ lng:-80.48735331564392,lat: -1.050350248058471},
{ lng:-80.4899115973638, lat:-1.05355810192763},
{ lng:-80.48975511146416,lat: -1.061515900975051},
{ lng:-80.48817395566228,lat: -1.063847927896651},
{ lng:-80.4847766491902, lat:-1.060176358603015},
];