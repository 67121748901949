import React from "react";
import Video from "../../videos/video.mp4";
import {
	Heading,
	Iconos,
	IconWrapper,
	MainSec,
	Subtitle,
	Control,
	TextWrapper,
	TextIcons,
	House,
	HomeLinks,
	Company,
	VideoBg,
	MainBg,
} from "./MainDashboard.elements";

export const MainDashBoard = ({
	heading,
	sub1,
	sub2,
	lightBg,
	lightText,
	lightTextDesc,
	buttonLabel,
	primary,
	location,
	plan1,
	plan2,
	plan3,
	servicio1,
}) => {
	return (
		<>
			<MainBg>
				<VideoBg autoPlay loop muted src={Video} type="video/mp4" />
			</MainBg>

			<MainSec>
				<TextWrapper>
					<Heading>{heading}</Heading>
				</TextWrapper>
				<TextWrapper>
					<Subtitle>{sub1}</Subtitle>
				</TextWrapper>
				<IconWrapper>
					<HomeLinks to="/planes-hogar">
						<Iconos>
							<House />
							<TextIcons>{plan1}</TextIcons>
						</Iconos>
					</HomeLinks>
					<HomeLinks to="/planes-ultra">
						<Iconos>
							<Control />
							<TextIcons>{plan2}</TextIcons>
						</Iconos>
					</HomeLinks>

					<HomeLinks to="/planes-corporativos">
						<Iconos>
							<Company />
							<TextIcons>{plan3}</TextIcons>
						</Iconos>
					</HomeLinks>
				</IconWrapper>

				{/* <TextWrapper>
				<Subtitle>{sub2}</Subtitle>
			</TextWrapper>

			<IconWrapper>
				<Iconos>
                <Control />
                <TextIcons>
                    {servicio1}
                    </TextIcons>
                </Iconos>
			</IconWrapper> */}
			</MainSec>
		</>
	);
};
