import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Helmet } from 'react-helmet';

export const SpeedTest = ({ location }) => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [location]);

	const [loading, setLoading] = useState(false);

	const handleOnload = () => {
		setLoading(true);
	};

	return (
		<>
			<Helmet>
				<title>SpeedTest | XfinityNet</title>
				<meta name="description" content="Test de velociad de XfinityNet" />
			</Helmet>
			<div style={{ backgroundColor: '#1c1c1c' }}>
				{!loading && (
					<div className="vertical-center">
						<div className="container">
							<div className="row justify-content-center">
								<div className="sk-chase">
									<div className="sk-chase-dot"></div>
									<div className="sk-chase-dot"></div>
									<div className="sk-chase-dot"></div>
									<div className="sk-chase-dot"></div>
									<div className="sk-chase-dot"></div>
									<div className="sk-chase-dot"></div>
								</div>
							</div>
						</div>
					</div>
				)}
				<Fade>
					<iframe
						width="100%"
						height="650px"
						frameBorder="0"
						onLoad={handleOnload}
						style={{
							display: loading ? 'block' : 'none',
							backgroundColor: '#1c1c1c',
							marginBottom: '-6px',
						}}
						title="Speed Test"
						src="http://xfinitynet.speedtestcustom.com"
					></iframe>
				</Fade>
			</div>
		</>
	);
};
